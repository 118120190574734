@use "src/theme" as *;

.media {
  @include flexColumn($alignX: center);
  height: 100%;
  margin: 24px 0 20px;
  width: 100%;

  label {
    font-weight: 900;
  }

  .hr {
    background-color: $primary;
    height: 4px;
    margin-bottom: 24px;
    width: 200px;
  }

  .item {
    cursor: pointer;
    line-height: 20px;
    margin-top: 28px;
    position: relative;
    width: 100%;

    .copied {
      background-color: $gray;
      border-radius: 4px;
      font-size: $fontLabel;
      left: 0;
      margin: 0 auto;
      opacity: 0;
      position: absolute;
      right: 0;
      top: -20px;
      transition: opacity 0.1s ease-in-out;
      width: 52px;
      &.show {
        opacity: 0.8;
      }
    }
  }
}
