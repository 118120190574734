@use "src/theme" as *;

.breadcrumbs {
  @include flexRow();
  color: $light;
  text-transform: capitalize;
  @media screen and (max-width: 768px) {
    flex-wrap: nowrap;
    align-items: center;
  }
  @media screen and (max-width: 599px) {
    flex-wrap: wrap;
    align-items: center;
  }

  .linkButton {
    @include flex();
    border-bottom: 2px solid transparent;
    cursor: pointer;
    color: $light;
    font-weight: 900;
    height: 22px;
    padding-bottom: 2px;
    transition: border-bottom 0.2s ease-in-out;

    &:hover,
    &:focus {
      border-color: $light;
      outline: none;
    }
  }

  label {
    font-weight: 900;
    padding-left: 32px;
    @media screen and (max-width: 599px) {
      padding-left: 12px;
    }
  }

  @media screen and (max-width: 599px) {
    .second {
      padding-left: 0;
    }
  }
}
