@use "src/theme" as *;

.menu {
  background-color: $headerBGColor;

  .content {
    @include flexRow(center, center, nowrap);
    height: 88px;
    margin: 0 32px;
    position: relative;
    @media screen and (max-width: 425px) {
      margin: 0 12px;

      button {
        min-width: 106px;
        padding: 12px;
        margin-bottom: 4px;
      }
    }
  }

  .container {
    @include flexRow(flex-start, center, nowrap);
    width: 100%;
  }

  .icon {
    @include flex();
    margin-right: 32px;
    @media screen and (max-width: 425px) {
      margin-right: 16px;
    }

    svg {
      cursor: pointer;
      fill: $dark;
      height: 20px;
      width: 26px;
    }

    &.light svg {
      fill: $light;
    }

    &:hover,
    &:focus-visible {
      opacity: 0.5;
      outline: none;
    }
  }

  &.noBG {
    background-color: transparent !important;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;
    @media screen and (max-width: 599px) {
      .content {
        margin: 0 12px;
        height: 52px;
      }
    }

    .container {
      max-width: unset;
    }
  }
}
