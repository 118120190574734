@use "src/theme" as *;

.sources {
  @include flexColumn();
  margin: 40px 0 20px;
  max-width: 312px;
  width: 100%;
  @media screen and (max-width: 768px) {
    margin-top: 24px;
  }

  label {
    color: $link;
    font-size: $fontLabel;
    margin-top: 20px;
    width: 100%;

    span {
      color: $dark;
    }
  }

  .helpline {
    @include flexColumn();
    margin-bottom: 20px;
    width: 100%;

    p {
      margin-top: 20px;
      width: 100%;
      word-break: break-all;
    }
  }
}
