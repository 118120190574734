@use "src/theme" as *;

.podcasts {
  @include flexColumn($alignX: center);
  margin: 0 auto;
  min-height: calc(100vh - 160px);
  padding-top: 40px;
  position: relative;
  width: 100%;

  .bg {
    position: absolute;
    width: 100%;
  }

  .bg svg {
    height: auto;
    opacity: 0.3;
    position: fixed;
    top: 0;
    z-index: -1;
    transform: rotateX(180deg);
    @media screen and (min-width: 1441px) {
      max-width: 1440px;
    }
    @media screen and (max-width: 768px) {
      width: 150%;
    }
  }
}

.default {
  @include flexColumn(center, center);
  max-width: 80%;
  text-align: center;
  width: 100%;
  @media screen and (max-width: 1100px) {
    max-width: calc(100% - 100px);
  }
  @media screen and (max-width: 768px) {
    max-width: calc(100% - 56px);
  }

  .card {
    @include flexRow(space-between, center, nowrap);
    background-color: $light;
    box-shadow: 12px 8px 0 rgba(black, 0.3);
    margin: 24px 0 32px;
    padding: 40px;
    text-align: left;
    width: 100%;
    &:last-child {
      margin-bottom: 80px;
    }
    @media screen and (max-width: 1100px) {
      padding: 20px;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .text {
      @include flexColumn($alignY: center);
      white-space: pre-line;
      padding-right: 60px;
      @media screen and (max-width: 1100px) {
        padding-right: 32px;
      }
      @media screen and (max-width: 768px) {
        padding: 0;
        margin-bottom: 20px;
      }

      a {
        color: $dark;
        &:hover {
          color: rgba(black, 0.6);
        }
      }
      .subtitle {
        margin-top: 8px;
      }
    }

    .hr {
      background-color: $primary;
      height: 2px;
      margin: 16px 0;
      width: 100%;
    }

    .headshots {
      @include flexRow($wrap: nowrap);

      img {
        border-radius: 50%;
        box-shadow: 5px 5px 0px $primary;
        height: 160px;
        margin: 0 8px;
        width: 160px;
        @media screen and (max-width: 1100px) {
          height: 120px;
          width: 120px;
        }
        @media screen and (max-width: 768px) {
          height: 100px;
          width: 100px;
        }
      }

      &.wrap {
        @media screen and (max-width: 768px) {
          flex-wrap: wrap;

          img {
            width: 92px;
            height: 92px;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}
