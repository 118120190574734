@use "src/theme" as *;

.pageCard {
  display: flex;
  margin: auto;
  max-width: 732px;
  padding: 0;
  position: relative;
  width: 100%;
  @media screen and (max-width: 768px) {
    max-width: calc(100% - 8px);
  }

  .content {
    @include flexColumn();
    background-color: $light;
    min-width: 300px;
    padding: 44px 36px 28px;
    width: 100%;
    z-index: 5;
    @media screen and (max-width: 550px) {
      padding: 28px 16px;
    }
    @media screen and (max-width: 450px) {
      min-width: 270px;
    }

    &.withBorder {
      border: 1px solid $dark;
      border-top: 4px solid $primary;
      border-bottom: none;
      @media screen and (max-width: 450px) {
        padding: 20px 16px;
        min-width: 270px;
      }
    }
  }

  &:after {
    background-color: $dark;
    content: "";
    height: calc(100% - 64px);
    opacity: 0.35;
    position: absolute;
    right: -16px;
    top: 32px;
    width: 16px;
    z-index: 4;
    @media screen and (max-width: 550px) {
      width: 8px;
      right: -8px;
    }
  }

  &.leftShadow {
    @media screen and (max-width: 768px) {
      max-width: 96%;
    }
    @media screen and (max-width: 450px) {
      max-width: calc(96% - 24px);
    }

    &:after {
      height: calc(100% - 32px);
      right: -16px;
      width: 16px;
    }
    &:before {
      background-color: $dark;
      content: "";
      height: calc(100% - 32px);
      left: -16px;
      opacity: 0.35;
      position: absolute;
      top: 32px;
      width: 16px;
      z-index: 4;
    }
  }
}
