@use "src/theme" as *;

.supportMaterials {
  @include flexColumn(center, center);
  background-color: $light;
  padding-top: 20px;
  position: relative;
  text-align: center;
  width: 100%;
  @media screen and (max-width: 1091px) {
    margin-bottom: 32px;
    min-height: 768px;
  }
  @media screen and (max-width: 599px) {
    margin-top: 40px;
  }

  .img {
    height: auto;
    max-width: 1354px;
    width: 94%;
    transition: height 0.2s ease-in-out;
    @media screen and (max-width: 1091px) {
      position: absolute;
    }
    @media screen and (max-width: 599px) {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .title {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    position: absolute;
    text-align: center;
    top: 40px;
    left: 0;
    width: 100%;
    @media screen and (max-width: 1091px) {
      align-self: flex-start;
      margin-bottom: 28px;
      position: static;
      z-index: 10;
    }
  }

  .container {
    @include flexRow($alignX: center);
    bottom: 168px;
    position: absolute;
    left: 0;
    width: 100%;
    @media screen and (max-width: 1091px) {
      position: static;
    }
  }
}
