@use "src/theme" as *;

.speakers {
  @include flexColumn();
  background-color: #f5f5f5;
  margin: 120px 0 20px;
  max-width: 28%;
  padding: 20px 20px 0;
  width: 100%;

  .title {
    margin-bottom: 20px;
    text-transform: uppercase;
    @media screen and (max-width: 599px) {
      text-align: center;
    }
  }

  .single {
    @include flexRow($wrap: nowrap);
    font-weight: 900;
    margin-bottom: 32px;
    @media screen and (max-width: 599px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    img {
      height: 100px;
      margin-right: 8px;
      width: 100px;
      @media screen and (max-width: 599px) {
        margin: 0 0 8px;
      }
    }

    .text {
      @include flexColumn();
      @media screen and (max-width: 599px) {
        align-items: center;
      }

      .role {
        text-transform: uppercase;
        margin-bottom: 4px;
        opacity: 0.6;
      }

      .short {
        font-weight: 500;
        height: 0;
        margin-top: 4px;
        opacity: 0;
        transition: all 0.1s ease-in-out;
      }

      .notActive {
        height: auto;
        opacity: 1;
      }

      .collapsible {
        font-weight: 500;
        margin-top: 4px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.35s ease-in-out;
        white-space: pre-line;
      }

      .active {
        max-height: 1380px;
      }

      .fullbio {
        color: $link;
        cursor: pointer;
      }
    }
  }
}
