@use "src/theme" as *;

.podcastCard {
  display: flex;
  align-items: flex-end;
  position: relative;
  margin-left: 24px;
  &:first-child {
    margin-left: 0;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    margin: 0;
    width: calc((100% - 12px) / 2);
  }
  @media screen and (max-width: 450px) {
    width: 100%;
  }

  .bg {
    object-fit: cover;
    width: 100%;
    height: 188px;
    @media screen and (max-width: 600px) {
      height: 120px;
    }
  }

  .content {
    @include flexRow(space-between, center, nowrap);
    background-color: rgba(white, 0.86);
    color: $dark;
    cursor: pointer;
    font-weight: 900;
    padding: 4px;
    padding-left: 8px;
    position: absolute;
    text-align: left;
    width: 100%;
    z-index: 1;
    @media screen and (max-width: 600px) {
      justify-content: center;
      margin-bottom: 12px;
      min-height: 120px;
      padding: 4px 12px;
      position: static;
    }
    @media screen and (max-width: 450px) {
      min-height: 88px;
    }

    svg {
      height: 48px;
      padding-left: 8px;
      min-width: 48px;
    }
  }
}
