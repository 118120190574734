@use "src/theme" as *;

.imageLabel {
  @include flexRow($alignX: center);
  margin: 40px 0 20px;
  width: 100%;
  @media screen and (max-width: 599px) {
    margin-bottom: 0;
  }

  .item {
    @include flexColumn(center, center);
    padding: 0 12px;
    text-align: center;
    width: 32.5%;
    @media screen and (max-width: 599px) {
      margin-bottom: 20px;
      width: 50%;
    }

    .percent {
      padding: 0 12px;
      width: auto;
      color: $primary;
      border-bottom: 4px solid $primary;
      span {
        font-size: $fontHeading4;
      }
    }

    .image {
      height: 100px;
      width: auto;
      transition: margin 0.2s ease-in-out;
      &:hover {
        margin: -4px 0 4px;
      }
      @media screen and (max-width: 599px) {
        height: 80px;
      }
    }

    .label {
      margin: 32px 0 12px;
      @media screen and (max-width: 599px) {
        margin-top: 20px;
      }
    }
    .sublabel {
      margin-top: 16px;
      &.withLabel {
        margin-top: 0;
      }
    }
  }

  .imageFooter {
    margin: 60px 0 20px;
    width: 367px;
    height: auto;
    @media screen and (max-width: 499px) {
      width: 100%;
    }
  }

  .roundImage {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $dark;
    border: 2px solid $dark;
    border-radius: 50%;
    height: 96px;
    width: 96px;
  }
}
