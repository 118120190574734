@use "src/theme" as *;

.masonry {
  margin: 40px auto;
  width: 100%;
  z-index: 2;
  @media screen and (max-width: 750px) {
    margin-top: 20px;
  }

  .wide {
    @include flexRow($alignX: center);
    width: 100%;
    @media screen and (max-width: 1100px) {
      display: none;
    }
  }

  .semi {
    display: none;
    width: 100%;
    @media screen and (max-width: 1100px) {
      @include flexRow($alignX: center);
    }
  }

  .column {
    @include flexColumn();
    width: 300px;
    margin: 0 12px;
    @media screen and (max-width: 380px) {
      width: 100%;
    }
  }

  a {
    line-height: 0;
    margin-bottom: 20px;
    width: inherit;
    &:focus {
      outline: none;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    }
  }

  img {
    cursor: pointer;
    background-color: $gray;
    height: auto;
    width: inherit;
    &:focus,
    &:hover {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    }
  }
}
