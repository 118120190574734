@use "src/theme/colors" as *;

.list {
  list-style: none;
  margin-top: 12px;
  padding-left: 20px;
  width: calc(100% - 20px);

  li {
    display: flex;
    margin-bottom: 16px;
    align-items: center;

    div {
      line-height: 20px;
      display: inline-block;
    }
    &:before {
      content: "\2022";
      font-size: 20px;
      font-weight: bold;
      border: none;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
}
