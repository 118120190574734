@use "src/theme" as *;

.container {
  background-color: rgba(black, 0.4);
  height: 100vh;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  transition: visibility 0.5s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 1000000;
  &.visible {
    visibility: visible;
  }
}

.sidebar {
  @include flexColumn();
  background-color: $light;
  height: 100vh;
  left: 0;
  padding: 20px 0;
  position: fixed;
  overflow-y: auto;
  transform: translateX(-100%);
  width: 300px;

  a {
    width: 100%;
  }

  .title {
    padding-left: 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }

  .side:focus {
    outline: none;
  }

  .toolkit {
    box-sizing: border-box;
    padding: 12px 20px;
  }

  .more {
    box-sizing: border-box;
    padding: 8px 20px;
  }

  .active {
    background-color: $gray;
    color: $dark;
  }
}

.close {
  @include flexColumn(center, center);
  background-color: $light;
  cursor: pointer;
  position: absolute;
  top: 0;
  transform: translateX(-100%);
  left: 0;
  width: 24px;
  height: 40px;
  transition: all 0.35s ease-in-out;
  opacity: 0;
  &:focus-visible {
    outline: 1px rgba(black, 0.4) dashed;
  }

  svg {
    height: 16px;
  }

  &.visible {
    left: 324px;
    opacity: 1;
  }
}

.outOfBounds {
  height: 100%;
  width: 100%;
}

//animation
.slideIn {
  animation: slide-in 0.5s forwards;
}

.slideOut {
  animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
