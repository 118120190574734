@use "src/theme" as *;

.toolkit {
  @include flexColumn();
  margin-top: 24px;
  width: 100%;

  .container {
    @include flexColumn();
    display: none;
    position: relative;
    width: 100%;
    margin-top: 24px;
    height: 100%;
    background-color: $gray;
    z-index: 2;
    @media screen and (max-width: 896px) {
      flex-direction: column-reverse;
    }
  }

  .background {
    @include flexColumn();
    position: absolute;
    top: 0;
    transition: all 0.15s ease-in-out;
    height: 100%;
    width: 100%;
    @media screen and (max-width: 896px) {
      position: static;
    }

    img {
      height: auto;
      width: 100%;
    }
  }

  .sources {
    margin-left: 900px;
    @media screen and (min-width: 1020px) and (max-width: 1224px) {
      margin-left: 700px;
    }
    @media screen and (max-width: 1019px) {
      display: none;
    }
  }

  .content {
    @include flexColumn();
    max-width: 732px;
    margin-top: 44px;
    margin-left: 108px;
    width: 100%;
    transition: all 0.15s ease-in-out;
    @media screen and (min-width: 1020px) and (max-width: 1224px) {
      max-width: 620px;
      margin-left: 40px;
    }
    @media screen and (max-width: 896px) {
      margin: 44px auto 0;
    }
    @media screen and (max-width: 768px) {
      margin-top: 32px;
      padding: 0 16px;
    }

    .pageCard {
      padding-right: 32px;
      @media screen and (max-width: 1224px) {
        padding-right: 16px;
        margin: 0;
      }
    }

    .sourcesMobile {
      display: none;
      @media screen and (max-width: 1019px) {
        display: flex;
        max-width: 100%;
        padding: 0 3px;
      }
    }

    .disclaimer {
      font-size: $fontLabel;
      margin: 28px 0 40px;
      padding-right: 64px;
      width: 100%;
      @media screen and (max-width: 1019px) {
        margin-top: 12px;
      }
      @media screen and (max-width: 768px) {
        padding-right: 0;
        padding: 0 3px;
      }
    }
  }

  .hide {
    display: flex;
    animation: fade_in_show 0.3s ease-in-out;
  }
}

@keyframes fade_in_show {
  0% {
    opacity: 0;
    z-index: 2;
  }

  100% {
    opacity: 1;
    z-index: 3;
  }
}
