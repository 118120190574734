@use "src/theme" as *;

.pageCarousel :global {
  width: 900px;
  @media screen and (max-width: 1024px) {
    width: 96%;
  }

  .slick-slide {
    pointer-events: none;
  }

  .slick-current {
    pointer-events: auto !important;
  }

  .slick-prev {
    left: 0;
    z-index: 2;
  }
  .slick-next {
    right: 0;
  }

  .slick-prev,
  .slick-next {
    height: 60px;
    top: 60%;
    width: 36px;
    &:before {
      content: "";
    }
    @media screen and (max-width: 864px) {
      display: none;
    }

    svg {
      height: 60px;
      fill: #5a5a5a;
      width: 36px;
    }
  }

  .slick-disabled {
    visibility: hidden;
  }
}

.subtitle {
  font-weight: 700;
  margin: 28px auto;
  max-width: 732px;
  text-align: center;
  white-space: pre-wrap;
  width: 100%;
  @media screen and (max-width: 768px) {
    max-width: 84%;
  }
}

.container:focus {
  outline: none;
}

.pageCard {
  text-align: center;
  min-height: 560px;
  white-space: pre-wrap;
  @media screen and (max-width: 768px) {
    white-space: normal;
  }

  p {
    width: 100%;
  }

  .content {
    @include flexColumn(center, center);
    visibility: hidden;
    transition: visibility 0.4s ease-in-out;
    width: 100%;
    &.visible {
      visibility: visible;
    }
  }
}

.footer {
  font-weight: 700;
  margin: 28px auto;
}
