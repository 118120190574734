@use "src/theme" as *;

.carousel {
  box-sizing: border-box;
  width: 100%;

  svg {
    cursor: pointer;
    fill: white;
    height: 9px;
    margin-top: 4px;
    padding-right: 2px;
    width: 9px;
    &:hover,
    &:focus {
      fill: $primary;
      outline: none;
    }
  }
}
