@use "src/theme" as *;

.ally {
  @include flexColumn();
  width: calc(100% - 16px);
  white-space: pre-wrap;
  @media screen and (max-width: 550px) {
    width: 100%;
  }

  p {
    width: 100%;
  }

  .allyList {
    display: block;
    margin: 12px 0;

    span {
      display: inline-block;
      font-size: 15px;
      font-weight: 900;
    }
  }

  .actions {
    @include flexRow($alignX: space-between);
    margin: 32px 0 12px;
    text-align: center;
    width: 100%;

    .item {
      @include flexColumn($alignX: center);
      width: 48%;
      @media screen and (max-width: 550px) {
        margin-bottom: 24px;
        width: 100%;
      }

      .hr {
        background-color: $dark;
        height: 4px;
        margin: 12px 0;
        width: 88px;
      }
    }
  }
}
