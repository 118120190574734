@use "src/theme" as *;

.titleDescription {
  @include flexColumn;
  padding-right: 32px;
  width: 100%;
  @media screen and (max-width: 599px) {
    padding-right: 0;
  }

  .description {
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 20px;

    .subtitle {
      font-weight: 700;
      margin-bottom: 12px;
      span {
        color: $dark;
        font-weight: 900;
      }
    }
  }

  a {
    font-weight: 700;
    margin-bottom: 12px;
  }

  .title span {
    color: $dark;
    font-weight: 900;
  }
}
