@use "src/theme" as *;

$duration: 0.2s;
$distance: 8px;
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);

.navigation {
  @include flexRow($alignX: center);
  width: 100%;
  z-index: 1;

  a {
    margin: 0 16px;
    position: relative;

    &:before,
    &:after {
      content: "";
      position: absolute;
      bottom: 2px;
      left: 0;
      right: 0;
      height: 4px;
      background-color: $primary;
    }
    &:before {
      opacity: 0;
      transform: translateY(-$distance);
      transition: transform 0s $easeOutBack, opacity 0s;
    }
    &:after {
      opacity: 0;
      transform: translateY(calc($distance/2));
      transition: transform $duration $easeOutBack, opacity $duration;
    }
    &:hover,
    &:focus-visible {
      outline: none;
      &:before,
      &:after {
        opacity: 1;
        transform: translateY(0);
      }
      &:before {
        transition: transform $duration $easeOutBack, opacity $duration;
      }
      &:after {
        transition: transform 0s $duration $easeOutBack, opacity 0s $duration;
      }
    }
    &.active {
      &:before,
      &:after {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .label {
    box-sizing: border-box;
    color: $dark;
    cursor: pointer;
    height: 40px;
    margin-bottom: 4px;
    padding: 8px 0;
    text-align: center;
  }
}
