@use "src/theme" as *;

.audio {
  @include flexColumn(center, center);
  margin-bottom: 20px;
  width: 100%;

  .divide {
    font-weight: 700;
    padding: 0 4px;
    @media screen and (max-width: 599px) {
      display: none;
    }
  }
}

:global(.rhap_container) :global {
  background-color: rgba(black, 0.1);
  background-color: transparent;
  box-shadow: none;
  padding: 4px 0 8px;
  z-index: 1;

  svg {
    width: 12px;
    fill: $secondary;
  }

  .rhap_controls-section {
    flex: none;
    margin: 0;
  }
  .rhap_main-controls button {
    font-size: $fontBody;
    width: 16px;
  }
  .rhap_progress-container {
    margin: 0 8px;
  }
  .rhap_progress-indicator {
    display: none;
  }
  .rhap_progress-bar-show-download {
    background-color: rgba(black, 0.2);
  }
  .rhap_time {
    color: $dark;
    font-size: $fontBody;
    font-weight: 700;
    width: 44px;
  }
  .rhap_volume-container button {
    flex: 0 0 auto;
    font-size: $fontBody;
    width: 16px;
  }
  .rhap_progress-filled,
  .rhap_volume-indicator {
    background-color: $secondary;
  }
  .rhap_download-progress,
  .rhap_volume-bar {
    background-color: rgba(black, 0.2);
  }

  @media screen and (max-width: 599px) {
    background-color: rgba(black, 0.05);
    padding: 20px 12px;
    svg {
      width: 20px;
    }

    .rhap_horizontal-reverse {
      flex-direction: column-reverse;
    }
    .rhap_main-controls {
      flex: 1;
      margin-bottom: 12px;

      button {
        border: $secondary 1px solid;
        border-radius: 50%;
        height: 60px;
        width: 60px;

        svg {
          width: 32px;
          padding-left: 4px;
        }
      }
    }
    .rhap_progress-container {
      width: 100%;
      margin: 0;
    }
    .rhap_progress-section {
      flex-wrap: wrap;
    }
    .rhap_current-time {
      width: calc(100% - 44px);
    }
    .rhap_volume-container {
      display: none;
    }
  }
}
