@use "src/theme" as *;

.terminology {
  @include flexColumn();
  width: calc(100% - 16px);
  @media screen and (max-width: 599px) {
    width: 100%;
  }

  .quote {
    @include flexColumn();
    margin: 20px 0;
    padding-left: 56px;
    position: relative;
    width: 100%;
    &.list {
      margin-bottom: 0;
    }
    @media screen and (max-width: 599px) {
      margin-bottom: 24px;
      padding-left: 44px;
    }

    p {
      width: 100%;
    }

    .leftIcon svg {
      fill: $primary;
      height: 44px;
      left: 0;
      opacity: 0.5;
      position: absolute;
      top: -8px;
      width: 44px;
      transition: all 0.2s ease-in-out;
      @media screen and (max-width: 599px) {
        height: 32px;
        top: 4px;
        width: 32px;
      }
    }

    .rightIcon svg {
      fill: $primary;
      height: 96px;
      opacity: 0.05;
      position: absolute;
      right: 0;
      top: 8px;
      transform: rotateY(180deg);
      width: 96px;
      transition: all 0.2s ease-in-out;
      @media screen and (max-width: 599px) {
        height: 72px;
        width: 72px;
      }
    }

    .collapse {
      color: $link;
      cursor: pointer;
      margin-top: 20px;
      &:focus-visible {
        outline: 1px rgba(black, 0.4) dashed;
      }
    }
  }

  .collapsible {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.35s ease-in-out;
  }

  .active {
    max-height: 1380px;
  }
}
