@use "colors" as *;

//Mixins
@mixin flex(
  $direction: column,
  $justifyContent: flex-start,
  $alignItems: flex-start
) {
  align-items: $alignItems;
  box-sizing: border-box;
  display: flex;
  flex-direction: $direction;
  justify-content: $justifyContent;
}

@mixin flexRow($alignX: flex-start, $alignY: flex-start, $wrap: wrap) {
  @include flex(
    $direction: row,
    $justifyContent: $alignX,
    $alignItems: $alignY
  );
  flex-wrap: $wrap;
}

@mixin flexColumn($alignX: flex-start, $alignY: flex-start) {
  @include flex($justifyContent: $alignY, $alignItems: $alignX);
}
