@use "src/theme" as *;

.episode {
  @include flexRow($alignX: center);
  width: 100%;

  .content {
    @include flexColumn();
    max-width: 780px;
    margin-right: 60px;
    width: 100%;
    @media screen and (max-width: 1200px) {
      margin-right: 32px;
      max-width: 724px;
    }
    @media screen and (max-width: 1050px) {
      margin-right: 0;
    }
    @media screen and (max-width: 768px) {
      max-width: calc(100% - 40px);
    }
  }

  .pageCard {
    max-width: 100%;
    position: relative;

    .padding {
      padding-right: 40px;
      @media screen and (max-width: 599px) {
        padding-right: 20px;
      }
    }

    .subtitle {
      font-weight: 700;
      margin-top: 12px;
    }

    .headerImage {
      height: auto;
      margin: 20px 0;
      max-height: 200px;
      object-fit: cover;
      width: 70%;
      @media screen and (max-width: 599px) {
        width: 100%;
      }
    }

    .body {
      margin: 20px 0;

      strong {
        font-weight: 900;
      }

      label {
        display: flex;
        margin: 0 auto;
        width: 80%;
      }
    }

    .articleImage {
      width: 100%;
      height: auto;
    }

    .downloads {
      @include flexRow($alignX: space-between);
      font-weight: 900;
      margin-bottom: 32px;
      min-width: 420px;
      width: 64%;
      @media screen and (max-width: 768px) {
        margin-bottom: 20px;
        min-width: unset;
        width: 100%;
      }
    }

    .downloadArticle {
      font-weight: 900;
      margin: 12px 0 20px;
      text-align: right;
      width: 100%;
    }
  }

  .speakerMobile {
    display: none;
    @media screen and (max-width: 1050px) {
      display: flex;
      margin-top: 28px;
      max-width: 100%;
    }
  }

  .disclaimer {
    background-color: rgba(white, 0.8);
    box-sizing: border-box;
    font-size: $fontLabel;
    margin: 28px 0 40px;
    padding: 20px;
    width: 100%;
    @media screen and (max-width: 1050px) {
      margin-top: 8px;
    }
  }

  .speaker {
    @media screen and (max-width: 1050px) {
      display: none;
    }
  }

  .episode {
    strong {
      font-weight: 900;
    }

    .subtitle {
      width: 80%;
      margin: 8px 0 20px;
      @media screen and (max-width: 1050px) {
        width: 100%;
      }
    }

    .body {
      margin-top: 8px;
    }

    .title {
      margin-top: 40px;
      margin-bottom: 20px;
    }

    .centered {
      text-align: center;
    }

    .sub {
      margin-bottom: 20px;
    }

    .rowTitle {
      @include flexRow($wrap: nowrap);
      margin-bottom: 8px;
      width: 100%;

      .number {
        font-size: 96px;
        font-weight: 900;
        line-height: 88px;
        margin-right: 8px;
      }

      div {
        width: auto;
      }
    }

    .quote {
      width: 72%;
      margin: 20px auto;
      @media screen and (max-width: 1050px) {
        width: 80%;
      }
    }

    .list {
      margin: 16px 0 0;
      @media screen and (max-width: 450px) {
        padding: 0 16px;
      }
    }

    .list li {
      margin-bottom: 16px;
    }
  }
}
