@use "src/theme" as *;

@mixin format($size: $fontHeading1, $lineHeight: 44px) {
  font-size: $size;
  font-weight: 700;
  line-height: $lineHeight;
}

.default {
  width: 100%;
  white-space: pre-wrap;

  &.h1 {
    @include format($lineHeight: 48px);
    font-weight: 900;
    margin-bottom: 12px;
  }

  &.h2 {
    @include format($fontHeading2, 40px);
  }

  &.h3 {
    @include format($fontHeading3, 28px);
    font-weight: 900;
  }

  &.h4 {
    @include format($fontHeading4, 24px);
    font-weight: 900;
  }

  &.h5 {
    @include format($fontHeading5, 20px);
  }
}

.uppercase {
  text-transform: uppercase;
}

.withMargin {
  margin: 12px 0;
}

.label {
  white-space: pre-wrap;
  width: 100%;

  span {
    color: $link;
  }

  &.primary span {
    color: $dark;
  }
}
