@use "src/theme" as *;

.transgender {
  @include flexColumn();
  width: 100%;

  p {
    width: 100%;
  }

  .transfamilies {
    @include flexRow();
    margin: 40px 0;
    width: 100%;
    @media screen and (max-width: 599px) {
      margin: 20px 0;
    }

    .text {
      @include flexColumn();
      padding-right: 12px;
      width: calc(40% - 20px);
      @media screen and (max-width: 599px) {
        width: 100%;
      }

      .caption {
        font-size: 10px;
        margin-bottom: 8px;
      }

      .title {
        font-size: 28px;
        font-weight: 900;
        line-height: 36px;
        margin-bottom: 8px;
        width: 100%;
      }
    }

    img {
      border-bottom: 4px solid $primary;
      height: 232px;
      max-width: 60%;
      object-fit: cover;
      @media screen and (max-width: 599px) {
        height: auto;
        margin-top: 12px;
        max-width: 100%;
        width: 100%;
      }
    }
  }

  .tips {
    @include flexColumn($alignX: center);
    text-align: center;
    width: 100%;

    .hr {
      background-color: $primary;
      height: 8px;
      margin: 20px 0;
      width: 196px;
    }

    .button {
      background-color: $light;
      border: $primary 2px solid;
      box-sizing: border-box;
      color: $link;
      font-weight: 900;
      font-size: $fontLabel;
      height: 44px;
      margin: 20px 0 8px;
      padding: 12px 16px;
      width: 140px;
      &:hover,
      &:focus-visible {
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
        outline: none;
      }
    }
  }
}
