@use "src/theme" as *;

.modal {
  @include flexColumn(center, center);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 4;
  top: 0;
  left: 0;

  .space {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .content {
    @include flexColumn($alignX: flex-end);
    background-color: $light;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    width: auto;
    height: 88%;
    padding: 24px;
    position: absolute;
    @media screen and (max-width: 699px) {
      height: auto;
      width: calc(100% - 24px);
    }

    img {
      height: 100%;
      width: 100%;
    }
  }

  svg {
    fill: $secondary;
    width: 20px;
    position: absolute;
    right: 16px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
}
