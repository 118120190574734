@use "src/theme" as *;
.podcast {
  @include flexColumn($alignX: center);
  background-color: #fed8c3;
  margin: 0 0 64px;
  position: relative;
  width: 100%;

  .container {
    @include flexRow($alignY: stretch, $alignX: center);
    width: 100%;
    z-index: 1;
    @media screen and (max-width: 950px) {
      align-items: center;
      flex-direction: column-reverse;
    }

    .left {
      position: relative;
      width: 40%;
      @media screen and (max-width: 950px) {
        height: 360px;
        width: 100%;
      }
    }

    .bg {
      position: absolute;
      margin: 0 auto;
      height: 100%;
      left: 0;
      object-fit: contain;
      right: 0;
      top: 0;
      width: auto;
    }

    .container {
      @include flexColumn(center, center);
      height: 100%;
      max-width: 56%;
      padding: 56px 28px;
      position: relative;
      text-align: center;
      z-index: 1;
      @media screen and (max-width: 1024px) {
        max-width: 60%;
      }
      @media screen and (max-width: 950px) {
        padding: 56px 0 0;

        max-width: calc(100% - 40px);
      }

      a {
        color: $dark;
        &:hover {
          color: rgba(black, 0.6);
        }
      }
    }

    .subtitle {
      font-weight: 700;
      line-height: 28px;
      margin: 12px 0 24px;
    }

    .featured {
      @include flexRow($alignY: center, $alignX: space-between);
      background-color: rgba(white, 0.72);
      padding: 32px 24px;
      text-align: left;
      @media screen and (max-width: 600px) {
        padding: 20px;
      }

      .text {
        @include flexColumn();
        width: calc(68% - 16px);
        @media screen and (max-width: 600px) {
          margin-bottom: 20px;
          width: 100%;
        }
        @media screen and (max-width: 400px) {
          white-space: pre-line;
        }
      }

      .speaker {
        font-weight: 700;
        margin: 8px 0;
        white-space: pre-line;
      }

      .image {
        display: flex;
        cursor: pointer;
        position: relative;
        width: 32%;
        &:hover {
          filter: brightness(0.95);
        }
        @media screen and (max-width: 600px) {
          height: 200px;
          width: 100%;
        }

        svg {
          border-radius: 50%;
          position: absolute;
          width: 80px;
          margin: auto;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }

        img {
          height: auto;
          width: 100%;
          @media screen and (max-width: 600px) {
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .others {
      @include flexRow(flex-start, center, nowrap);
      margin-top: 24px;
      width: 100%;
      @media screen and (max-width: 600px) {
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
  }

  .bottombg {
    bottom: -52px;
    position: absolute;
    width: 100%;
    @media screen and (max-width: 600px) {
      bottom: -38px;
      right: 0;
      height: 60px;
      width: 120%;
    }
  }
}
