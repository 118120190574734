@use "src/theme" as *;

.email {
  @include flexColumn();
  border-top: 4px solid $primary;
  border-bottom: 4px solid $primary;
  line-height: 20px;
  margin: 36px auto 12px;
  padding: 20px 0;

  .name {
    font-weight: 900;
  }
  p {
    text-align: left;
  }
  label {
    color: $link;
  }
}
