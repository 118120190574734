@use "src/theme" as *;

.card {
  @include flexColumn(center, center);
  background-color: $light;
  border: 1px solid $dark;
  border-radius: 2px;
  color: $dark;
  cursor: pointer;
  margin: 72px 16px 12px;
  max-width: 332px;
  min-height: 208px;
  padding: 64px 20px 44px;
  position: relative;
  width: 100%;
  transition: margin 0.2s ease-in-out;
  @media screen and (max-width: 375px) {
    width: calc(100% - 32px);
  }
  &:hover,
  &:focus-visible {
    color: $dark;
    outline: none;
    margin-top: 64px;
  }

  img {
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: -64px;
  }

  p {
    font-weight: 700;
    margin-top: 20px;
    width: 100%;
  }
}
