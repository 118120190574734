@use "src/theme" as *;

.resourceGuide {
  @include flexColumn();
  padding-bottom: 32px;
  white-space: pre-wrap;
  width: calc(100% - 20px);
  @media screen and (max-width: 599px) {
    width: 100%;
  }

  p {
    width: 100%;
  }

  .hotlines {
    @include flexRow($alignX: space-between);
    height: 304px;
    width: 100%;
    @media screen and (max-width: 599px) {
      @include flexColumn($alignX: center);
      height: auto;
    }
  }

  .hotline {
    @include flexColumn($alignX: center);
    border: 1px solid $primary;
    height: 100%;
    text-align: center;
    width: 32%;
    @media screen and (max-width: 599px) {
      height: auto;
      margin-bottom: 16px;
      width: 100%;
    }

    img {
      height: auto;
      width: 100%;
    }

    .text {
      @include flexColumn();
      padding: 20px;
      width: 100%;
      word-wrap: break-word;
    }
  }

  .trevor,
  .rain {
    border: none !important;
    margin: 40px 0 12px;
    width: 100%;

    img {
      height: 64px;
      width: auto;
      @media screen and (max-width: 599px) {
        height: 52px;
      }
    }
  }

  .mental {
    margin: 12px 0;
  }

  .sources {
    width: 100%;
    margin: 0;
    max-width: 100%;

    label {
      font-size: $fontBody;
    }
  }
}
