@use "src/theme" as *;

.banner :global {
  .slick-slide {
    pointer-events: none;
  }

  .slick-current {
    pointer-events: auto !important;
  }

  .slick-dots {
    text-align: right;
    left: 40px;
    top: 80px;
    width: 12px;
    @media screen and (max-width: 599px) {
      top: 40px;
      left: 20px;
    }

    li {
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 50%;
      float: left;
      height: 9px;
      margin: 3px 0;
      width: 9px;
      &:hover {
        background-color: rgba(0, 0, 0, 0.8);
      }

      div:focus-visible {
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.8);
        outline: 1px rgba(black, 0.4) dashed;
      }
    }

    .slick-active {
      background-color: black; //color instead of variable for ie11
    }
  }
}

.root {
  @include flex();
  background-color: $gray;
  transition: all 0.2s ease-in-out;
  width: 100%;

  .image,
  .imageMobile {
    height: auto;
    max-width: 100vw;
    width: 100%;
  }

  .image {
    @media screen and (max-width: 599px) {
      display: none;
    }
  }

  .imageMobile {
    display: none;
    @media screen and (max-width: 599px) {
      display: block;
    }
  }

  &:focus-visible {
    opacity: 0.8;
  }
}
