@use "src/theme" as *;

.toolkits {
  @include flex();
  image-rendering: -webkit-optimize-contrast;
  margin-top: 40px;
  position: relative;
  width: 100%;

  .content {
    @include flexColumn(center, center);
    width: 100%;
    text-align: center;
  }

  .bg {
    position: absolute;
    max-height: 508px;
    width: 100%;
    bottom: 0;
  }

  .title {
    margin: 0 8px;
    width: auto;
  }
}
