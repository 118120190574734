@use "src/theme" as *;

a {
  color: $link;
  cursor: pointer;
  text-decoration: none;
  &:hover,
  &:focus-visible {
    color: $linkHover;
    // outline: none;
  }
}
