@use "src/theme" as *;

.sanvello {
  @include flex();
  position: relative;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .container {
    @include flexColumn($alignX: center);
    background-color: rgba(#ffffff, 0.85);
    height: 100%;
    max-width: 96%;
    padding: 56px 120px;
    position: relative;
    z-index: 1;
    @media screen and (max-width: 1280px) {
      max-width: calc(100% - 160px);
      padding: 56px 0;
      width: 100%;
    }
    @media screen and (max-width: 750px) {
      align-items: center;
      max-width: 440px;
      padding: 40px 20px;
      text-align: center;
    }
    @media screen and (max-width: 450px) {
      max-width: unset;
    }
  }

  .content {
    @include flexColumn();
    max-width: 870px;
    transition: padding-left 0.2s ease-in-out;
    @media screen and (min-width: 750px) and (max-width: 1150px) {
      margin-right: 80px;
    }

    .top {
      @include flexRow($alignY: center);
      width: 100%;
      @media screen and (max-width: 965px) {
        justify-content: center;
      }

      .logo {
        height: 160px;
        image-rendering: -webkit-optimize-contrast;
        padding-right: 20px;
        width: auto;
      }

      .text {
        @include flexColumn();
        font-weight: 700;
        width: calc(100% - 320px);
        @media screen and (max-width: 965px) {
          padding: 12px 12px 0 28px;
          width: 100%;
        }

        .subtitle {
          line-height: 24px;
          margin-top: 8px;
        }
      }
    }

    .bottom {
      @include flexRow($alignY: center);
      padding: 0;
      width: 100%;
      @media screen and (max-width: 965px) {
        justify-content: center;
        padding: 0 12px;
      }

      .download {
        @include flexColumn();
        margin: 28px 0 16px;
        @media screen and (max-width: 965px) {
          border-left: solid 5px #1ecb8f;
          padding-left: 20px;
        }
        @media screen and (max-width: 750px) {
          margin-top: 20px;
        }

        .downloadText {
          @include flexColumn();
          font-weight: 900;
          max-width: 320px;
          text-align: left;
          width: 100%;
          @media screen and (max-width: 965px) {
            max-width: 100%;
          }

          p {
            font-weight: 700;
            line-height: 28px;
            margin: 4px 0 12px;
            width: 100%;
          }
        }

        .image img {
          cursor: pointer;
          height: 52px;
          margin-right: 12px;
          transition: height 0.2s ease-in-out;
          width: auto;
          @media screen and (max-width: 750px) {
            margin-right: 4px;
            height: 44px;
          }
          @media screen and (max-width: 375px) {
            height: 38px;
          }

          &:hover,
          &:focus-visible {
            outline: none;
            transform: scale(1.02);
          }
        }
      }

      .hr {
        background-color: #1ecb8f;
        height: 140px;
        margin: 0 20px;
        width: 5px;
        @media screen and (max-width: 965px) {
          display: none;
        }
      }

      .footer {
        line-height: 24px;
        max-width: calc(100% - 430px);
        width: 100%;
        @media screen and (max-width: 965px) {
          max-width: unset;
        }
      }
    }
  }

  .app {
    height: auto;
    position: absolute;
    right: -120px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 204px;
    @media screen and (max-width: 750px) {
      display: none;
    }
  }

  .appMobile {
    display: none;
    height: auto;
    margin: 20px auto;
    position: static;
    width: 204px;
    @media screen and (max-width: 750px) {
      display: block;
    }
  }
}
